.titleLabel {
  font-size: 13px;
  color: rgb(116, 116, 116);
}
.cross {
  padding-right: 4px;
  color: rgb(116, 116, 116);
  cursor: pointer;
}
.input {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  height: fit-content;
}
.input:focus {
  border: solid black 1px;
  outline: 0;
}
.tagsList {
  padding: 5px;
  display: flex;
  list-style: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
}
.tag {
  display: flex;
  align-items: center;
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  height: 100%;
  min-height: 32px;
  padding: 0 4px;
  margin-right: 5px;
  width: 250px;
}
.searchBar {
  border: none;
  outline: none;
  width: inherit;
}
.select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
  width: 100%;
  background-color: #fff;
  box-shadow: none;
  border: none;
}
.dropDown {
  width: 100%;
}

.dropdownRow {
  height: fit-content;
  width: 100%;
  border-top: 1px solid #aaa;
  text-align: left;
  padding: 6px;
  background-color: #fff;
}
.dropdownRow:hover {
  background-color: #5897fb;
  color: white;
}
.Error {
  display: flex;
  margin: 8px 0px 20px 5px;
  align-items: center;
  color: red;
  padding: 0;
}
.errContainer {
  display: flex;
  width: fit-content;
  justify-content: center;
  align-items: center;
  margin: 0;
}
.errorAsterisk {
  margin: 0px;
  color: red;
}
