.container {
  display: flex;
  z-index: 4;
  font-family: "Poppins", sans-serif;
  height: 100%;
  width: fit-content;
}
.main {
  width: 100%;
  padding: 20px;
}
.sideBar {
  background: #fff;
  color: #686a76;
  width: 250px;
  box-shadow: 1px 1px 1px rgb(220, 218, 218);
}
.active {
  background: #fff;
  color: #686a76;
  width: 90px;
  box-shadow: 1px 1px 1px rgb(220, 218, 218);
  overflow-y: auto;
}

.topSection {
  display: flex;
  align-items: center;
  padding: 30px 15px;
}
.avatar {
  width: 55px;
  height: 55px;
}
.avatarIcn {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.logo {
  font-weight: 700;
  margin-bottom: 0;
  font-size: 1.1rem;
  margin-left: 10px;
  color: #212529;
}
.bars {
  display: flex;
  font-size: 25px;
  margin-left: 50px;
  box-shadow: 2px 2px 2px rgb(220, 218, 218);
}
.link {
  display: flex;
  justify-content: left;
  font-weight: 300;
  color: inherit;
  padding: 10px 15px;
  text-align: left;
  font-size: 14.4px;
  gap: 15px;
  transition: all 0.5s;
  text-decoration: none;
}
.linkHover {
  color: #686a76;
}
.link:hover,
.linkHover:hover {
  background-color: red;
  color: #fff;
  transition: all 0.5s;
}
.icon,
.linkText {
  font-size: inherit;
  color: inherit;
}

.selectedBtn {
  background-color: red;
  color: #fff;
}
.linkActive {
  color: inherit;
  transition: all 0.5s;
  text-decoration: none;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  padding: 5px;
}
.linkActive:hover {
  color: #fff;
}

.selected {
  background-color: red;
  color: #fff;
  transition: all 0.5s;
}
.deselected {
  color: #686a76;
}

.linkTextActive {
  font-size: 10px;
  padding: 0;
  margin: 0%;
}
.iconActive {
  padding-bottom: 0%;
  margin-bottom: 0%;
}
.btn {
  background-color: #fff !important;
  border: 0ch !important;
}
.dropdownBtn {
  display: flex !important;
  align-items: center !important;
  justify-content: space-evenly !important;
}
.dropBtn {
  border: 0ch;
  display: flex;
  color: #fff;
  padding: 5px 10px;
  gap: 15px;
  transition: all 0.5s;
  text-decoration: none;
}

.linkHover:focus {
  color: #fff !important;
}
