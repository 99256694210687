/* Styling for Link tag Label */
.link {
  display: flex;
  justify-content: left;
  font-weight: 300;
  padding: 10px 15px;
  text-align: left;
  font-size: 14.4px;
  gap: 5px;

  color: inherit;
  transition: all 0.3s;
  text-decoration: none;
}

/* Styling for the entire Link  */
.linkHover {
  color: #686a76;
}
.journeyTextLink {
  color: #686a76;
  background-color: #eef5f9;
  opacity: 0.4;
  pointer-events: none;
  cursor: default;
}

/* Styling for the hover action  */
.link:hover,
.linkHover:hover {
  background-color: red;
  color: #fff !important;
  transition: all 0.3s ease;
}
.linkText {
  font-size: 15px;
  color: inherit;
}
.linkActive {
  color: inherit;
  transition: all 0.3s;
  text-decoration: none;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  padding: 5px;
}
.linkActive:hover {
  color: inherit;
}
.icon {
  font-size: inherit;
  color: inherit;
  margin-right: 10px;
  transition: all 0.3s;
}
.iconActive {
  padding-bottom: 0%;
  margin-bottom: 0%;
}
.linkTextActive {
  font-size: 10px;
  padding: 0;
  margin: 0%;
}
.selected {
  background-color: red;
  color: #fff !important;
  transition: all 0.5s;
}
