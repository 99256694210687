.imgContainer {
  vertical-align: middle;
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
  margin-left: 220px;
  width: 100px;
}
.removeImage {
  margin-top: 105px;
  margin-left: -105px;
  margin-bottom: 10px;
  color: red;
}
