.checkbox {
  width: 20px;
  justify-content: center;
  align-items: center;
  margin-left: 9.5em;
  transform: scale(1.5, 1.5);
}

.titleRow {
  margin-bottom: 35px;
  margin-top: 35px;
  display: flex;
}

.Container {
  height: 90%;
  max-height: auto;
  justify-content: center;
  padding: 1em 2.3em 3em;
  width: auto;
  max-width: 100%;
  overflow-y: scroll;
}

.imgContainer {
  vertical-align: middle;
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: 100px;
  margin-left: 220px;
  width: 100px;
}

.titleLabel {
  font-size: 13px;
  color: rgb(116, 116, 116);
}

.titleInput {
  height: 35px;
  border-radius: 0%;
  width: 82.8%;
  margin-left: auto;
}

.Error {
  display: flex;
  margin: 8px 0px 20px 5px;
  align-items: center;
  color: red;
  padding: 0;
}
.loginError {
  margin-bottom: 1em;
  margin-top: -1.7em;
}

.usernameError {
  display: flex;
  margin-left: 15.6em;
  color: red;
}
.loginUsernameError {
  margin-bottom: 1em;
  margin-top: -2.8em;
}
