.btn {
  background-color: #28a745;
  border: #28a745;
  color: white !important;
  margin: 10px;
}

.btn > a {
  color: inherit;
}
.btn:hover,
.btn:focus,
.btn:active,
.btn > a:active {
  background-color: #218838;
  border: #218838;
}
.btn:focus,
.btn:active {
  box-shadow: 0 0 0 0.2rem rgb(40 167 69 / 50%);
}

.btnStyle {
  display: flex;
}
.btn:disabled{
  background-color: #28a745;
  border: #28a745;
  color: white !important;
  margin: 10px;
}