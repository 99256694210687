.radioContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 35px 0;
}
.radioOptionContainer{
 display: inherit;
}
.radioInput{
    margin: 0 4px 0 10px;
}
.radioLabel{
  font-size: 13px;
  color: rgb(116, 116, 116);
}
.radioInputLabel{
    font-size: 14px;
}