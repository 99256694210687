.Container {
  height: 100%;
  width: 100%;
  padding: 3em 3em 3em;
  overflow-y: scroll;
}

.imgContainer {
  vertical-align: middle;
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
  width: 100px;
  margin: 0 10px;
}
h1 {
  color: #6c7ac9;
}

.pagination {
  display: flex;
  justify-content: space-between;
  list-style: none;
  cursor: pointer;
  width: 13%;
  margin-left: 80em;
  margin-top: 2em;
}

.pagination a {
  padding: 10px;
  color: #333;
  text-decoration: none;
}

.pagination a:hover {
  background: linear-gradient(to bottom, #585858 0%, #111 100%);
  color: #fff;
  border: none;
}

.pagination__link--active a {
  color: #333;
  background: linear-gradient(to bottom, #fff 0%, #dcdcdc 100%);
  border: 1px solid #333;
}

.pagination__link--disabled a {
  color: rgb(198, 197, 202);
  border: 1px solid rgb(198, 197, 202);
}
.MultiImg {
  display: flex;
  margin-left: 210px;
}
.tableHead{
  display: flex;
  align-items: center;
}