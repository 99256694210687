.btn,
.btnActive,
.btn:hover,
.btnActive:hover,
.btnActive:focus,
.btnActive:active,
.btnActive > a:active,
.btn:focus,
.btn:active,
.btn > a:active {
  color: #212529;
}
.btn {
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  height: fit-content;
  margin: 10px;
}
.btn:hover,
.btn:focus,
.btn:active,
.btn > a:active {
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgb(248 249 250 / 50%);
}
.btnActive {
  background-color: #ffc107;
  border-color: #ffc107;
  height: fit-content;
  margin: 10px;
}
.btnActive:hover,
.btnActive:focus,
.btnActive:active,
.btnActive > a:active {
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgb(255 193 7 / 50%);
}

.btnActive:disabled{
  background-color: #ffc107;
  border-color: #ffc107;
  height: fit-content;
  margin: 10px;
}
.btn:disabled{
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  height: fit-content;
  margin: 10px;
}