.btn {
  background-color: #c82333;
  border: #bd2130;
  color: white !important;
  margin: 10px;
}
.btn:disabled{
  background-color: #c82333;
  border: #bd2130;
  color: white !important;
  margin: 10px;
}

.btn > a {
  color: inherit;
}
.btn:hover,
.btn:focus,
.btn:active,
.btn > a:active {
  background-color: #c82333;
  border: #bd2130;
}
.btn:focus,
.btn:active {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.popUpContainer {
  position: absolute;
  top: 246px;
  left: 50%;
  margin-left: -200px;
  z-index: 1000;
  width: 400px;
  max-width: 90%;
  background-color: #ffffff;
  text-align: left;
  border: solid 1px #eeeeee;
  border-radius: 6px;
}

.popUpHeader {
  padding: 10px 10px;
  height: 70px;
  font-size: 16px;
  line-height: 20px;
  border-bottom: solid 1px #eeeeee;
}
.popUpFooter {
  text-align: right;
  border-top: solid 1px #e4e4e4;
  background-color: #f4f4f4;
  border-radius: 0 0 6px 6px;
  -moz-border-radius: 0 0 6px 6px;
  -webkit-border-radius: 0 0 6px 6px;
  padding: 0;
}
.btnSecondary {
  margin: 0;
  padding: 15px 20px;
  background-color: transparent;
  font-weight: normal;
  border: none;
  border-left: solid 1px #e4e4e4;
  color: #777;
  font-weight: bold;
  font-size: 16px;
}
.btnPrimary {
  margin: 0;
  padding: 15px 20px;
  background-color: transparent;
  font-weight: normal;
  border: none;
  border-left: solid 1px #e4e4e4;
  color: #489afe;
  font-weight: bold;
  font-size: 16px;
}
.btnSecondary:hover {
  background-color: transparent;
  border-left: solid 1px #e4e4e4;
  color: #287ade;
}
.btnPrimary:hover {
  background-color: transparent;
  border-left: solid 1px #e4e4e4;
  color: #287ade;
}

h4 {
  font-size: 16px;
  text-align: center;
}
