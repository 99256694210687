.titleLabel,
.logoLabel,
.descriptionLabel {
  font-size: 13px;
  color: rgb(116, 116, 116);
}
.descriptionRow,
.titleRow {
  margin-bottom: 35px;
  margin-top: 35px;
}
.titleInput {
  height: 35px;
  border-radius: 0%;
}
.titleInput:focus {
  box-shadow: none;
}
.descriptionInput {
  border-radius: 0%;
  font-size: 0.9em;
  padding: 5px !important;
}

hr {
  border: none;
  border-top: 1px dashed rgb(198, 198, 198);
  color: #fff;
  background-color: #fff;
  height: 1px;
  width: 100vh;
}
.removeImage {
  color: red;
  font-size: 12px;
}

.logoInput {
  border-radius: 0%;
  border: none;
  height: 100%;
  opacity: 0;
}
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dropZone {
  border: 2px solid rgb(188, 186, 186);
  position: relative;
  height: 200px;
  /* padding: 5px; */
  cursor: pointer;
  overflow-y: hidden;
  overflow-x: auto;
}
.logoUpload:hover {
  transition: 0.5s;
  border-color: #fff !important;
  background-color: #fff;
}
.logoInput:focus {
  padding: 0;
  outline: 0;
  box-shadow: 0 0 0 0 rgb(13 110 253 / 25%);
}
.logoUpload {
  width: 90px;
  height: 90px;
  border-radius: 20px;
  top: 10%;
  left: 8%;
  margin: 20px;
  margin-top: 0;
  padding: 10px;
  text-align: center;
}

.dzHide {
  opacity: 0;
  width: 100%;
  position: absolute;
  height: 100%;
  top: -1px;
  left: -1px;
  cursor: pointer;
}
.dzImagePreview {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
}
.dzImagePreview:hover {
  transition: 0.5s;
  border-color: #fff !important;
  z-index: 1000;
}
.dzImage:hover img {
  filter: blur(2px);
}
.dzImage:hover,
.imgSize {
  opacity: 1;
}
.dzImage {
  text-align: center;
}
.dzDetail {
  position: absolute;
  top: 50%;
  left: 52%;
  transform: translate(-50%, -50%);
  color: rgb(7, 7, 7);
}
span {
  /* background-color: rgb(186, 184, 184); */
}
.imgSize {
  font-size: 13px;
  background-color: rgb(171, 171, 171);
}
.imgName {
  font-size: 10px;
  background-color: rgb(171, 171, 171);
}
.removeImage {
  margin-top: 105px;
  margin-left: -105px;
  margin-bottom: 10px;
  color: red;
}
.dzHidden {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center; 
  overflow-x: auto;
  /* margin: 40px; */
  /* opacity: -1;
  height: 250px; */
}
.dzHiddensingleimg {
  display: none;
}
.dzText {
  color: rgb(158, 158, 158);
  position: relative;
  text-align: center;
  cursor: pointer;
  margin-top: 84px;
}
.dzTextimage {
  color: rgb(158, 158, 158);
  position: relative;
  text-align: center;
  cursor: pointer;
  opacity: 0;
}
.editImage {
  height: 70px;
  width: 70px;
  padding: 2px;
  margin: 2px;
  border: 1px solid rgb(147, 147, 147);
}
.editImageCol {
  padding: 2px;
  margin: 2px;
}
.isEdit {
  display: none;
}
.removeLink {
  color: red;
}
.dragFileElement {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.Error {
  display: flex;
  margin: 8px 0px 20px 5px;
  align-items: center;
  padding: 0;
  color: red;
}
.loginError {
  margin-bottom: 1em;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.imgContainer {
  vertical-align: middle;
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
  margin-top: 10px;
  width: 100px;
}
.errContainer {
  display: flex;
  width: fit-content;
  justify-content: center;
  align-items: center;
  margin: 0;
}
.errorAsterisk {
  margin: 0px;
  color: red;
}
