.tableHeadRow {
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid black;
}
.tableHeadRow > th {
  vertical-align: baseline;
}
.tableMilestone {
  padding-left: 10px !important;
}
.tableContainer {
  color: inherit;
  width: 100%;
  font-size: 0.9em;
}
.tableDataRow {
  height: 20px;
  vertical-align: middle;
}
.RowImage {
  height: 50px;
  width: 50px;
}
.btns {
  display: flex;
}
.imgContainer {
  vertical-align: middle;
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
  margin-left: 220px;
  margin-bottom: 10px;
  width: 100px;
}
.ascbtn {
  border-style: none;
  background-color: #fff;
  color: #666;
  transform: scale(0.6, 0.6);
  margin-bottom: -0.4em;
  height: 34px;
  padding: 1px;
}
.ascbtn:hover {
  color: #666;
  background-color: #fff;
}
.ascbtn:focus {
  color: #f2f2f2;
  background-color: #fff;
  box-shadow: none;
  transform: scale(0.6, 0.6);
  position: static;
}
.ascbtn:active {
  box-shadow: none;
}
.dscbtn {
  text-decoration: none;
  border-style: none;
  background-color: #fff;
  color: #666;
  padding: 0;
  align-items: center;
  align-self: center;
  margin-bottom: -0.6em;

  transform: scale(0.6, 0.6);
}
.dscbtn:hover {
  color: #666;
  background-color: #fff;
}
.dscbtn:focus {
  color: #f2f2f2;
  background-color: #fff;
  box-shadow: none;
  transform: scale(0.6, 0.6);
  position: static;
}
.dscbtn:active {
  box-shadow: none;
}
.mainDiv {
  /* display : inline-block;
  flex-direction : column;
  align-items : center;
  justify-content : center;
  text-align : center;
  margin-top : -2.1em;
  margin-bottom: -.5em; */
  text-align: center;
  margin-top: -10px;
  transform: scale(100%);
}
.divAsc {
  align-items: center;
  margin-bottom: -1.1em;
}
.divDesc {
  margin-left: auto;
  margin-bottom: -0.5em;
  margin-top: 0;
}
.wrapText {
  word-break: break-word;
  white-space: normal;
}
