.headerContainer {
  display: flex;
  justify-content: space-between;
  background-color: white;
  box-shadow: 2px 2px 2px rgb(0 0 0 / 10%);
  width: 100%;
  padding: 20px;
  height: fit-content;
}
.headerContainer > h4 {
  margin: 0 !important;
}
.subHeaderText {
  font-size: 1.3rem;
  font-weight: 500;
  margin-bottom: 0px !important;
  color: black;
  text-decoration: none;
}
.subHeaderTextDisable {
  font-size: 1.3rem;
  font-weight: 500;
  margin-bottom: 0px !important;
  color: black;
  opacity: 0.6;
  text-decoration: none;
  pointer-events: none;
}
