.cardContainer {
  display: block;
  width: 275px;
  text-align: center;
  padding: 4em 2em;
  margin: 4em 2em;
  position: relative;
  height: fit-content;
}
.imgContainer {
  width: fit-content;
  height: fit-content;
  position: absolute;
  top: -25px;
  left: 15px;
  z-index: 1;
}
.cardData {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 275px;
  height: fit-content;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.8;
}
.row1 {
  display: flex;
  height: 3em;
  width: 100%;
  margin: 5px;
  background-color: white;
  justify-content: space-evenly;
  padding: 10px;
  padding-left: 50px;
  font-size: large;
  font-weight: bold;
}
.row2 {
  display: flex;
  width: 100%;
  margin: 5px;
  background-color: white;
  justify-content: space-between;
  padding: 10px;
  font-weight: 500;
}
.row3 {
  display: flex;
  width: 100%;
  margin: 5px;
  background-color: white;
  justify-content: space-between;
  padding: 10px;
  font-weight: 500;
  text-decoration: none;
}
