.cancelBtn {
  margin: 2px;
  margin-bottom: 20px;
  border-radius: 5px !important;
  background-color: red !important;
  font-size: 15px !important;
}
.cancelBtn:focus,
.saveBtn:focus {
  box-shadow: none;
}
.cancelBtn:active {
  background-color: rgb(106, 106, 106) !important;
}
.saveBtn {
  margin: 2px;
  margin-bottom: 20px;
  border-radius: 5px !important;
  font-size: 15px !important;
  background-color: rgb(47, 137, 255) !important;
}
