.img {
  height: 75px;
  width: 60px;
  background-color: rgb(152, 152, 152);
}

.imgContainerSelected {
  border: 1px solid black;
  padding: 8px;
  height: fit-content;
  width: fit-content;
  transition: 0.3s ease;
  /* transform: scale(100%); */
  margin-top: 0.7em;
}
.imgContainer {
  margin: 20px;
  height: fit-content;
  width: fit-content;
  transition: 0.3s ease;
}

.logos {
  display: flex;
  overflow-x: scroll !important;
}
.titleLabel {
  font-size: 13px;
  color: rgb(116, 116, 116);
  width: fit-content;
}

.Error {
  display: flex;
  margin: 8px 0px 20px 5px;
  align-items: center;
  color: red;
  padding: 0;
}
.errContainer {
  display: flex;
  width: fit-content;
  justify-content: center;
  align-items: center;
  margin: 0;
}
.errorAsterisk {
  margin: 0px;
  color: red;
}
