.container {
  margin-bottom: 10px;
}
.ans {
  display: flex;
  width: fit-content;
  justify-content: center;
  align-items: center;
  margin: 0;
}
.ansBtnContainer {
  display: flex;
}
.inputZone {
  width: 25rem;
  border-radius: 0%;
  border-color: rgb(177, 177, 177);
}
.addBtn {
  margin: 5px;
}
.inputZone {
  margin: 05px;
}
.astrik {
  display: flex;
}
.label {
  font-size: 13px;
  color: rgb(116, 116, 116);
  vertical-align: middle;
}
.RowFirst {
  display: flex;
  align-items: center;
}
.input {
  margin-left: 10px;
}

.Error {
  display: flex;
  margin: 8px 0px 20px 5px;
  align-items: center;
  color: red;
  padding: 0;
}
.errorAsterisk {
  margin: 0px;
  color: red;
}
.icn {
  vertical-align: middle;
}
