.btn {
  background-color: #138496;
  border: #138496;
  color: white !important;
  margin: 10px;
}
.btn > a {
  color: inherit;
}
.btn:hover,
.btn:focus,
.btn:active,
.btn > a:active {
  background-color: #0f6a78;
  border: #0f6a78;
}
.btn:focus,
.btn:active {
  box-shadow: 0 0 0 0.2rem rgb(43 114 126 / 50%);
}
