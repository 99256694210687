* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
.formContainer {
  background-color: #fff;
  /* width: 90%; */
  /* margin: 4rem; */
  padding: 1em 3em 3em 3em;
  box-shadow: 2px 2px 2px rgb(0 0 0 / 10%), -1px 0 2px rgb(0 0 0 / 5%);
  max-width: 100%;
}
#loaderModal .modal-content {
  background-color: transparent;
  border: none;
}
body {
  overflow: hidden !important;
}
