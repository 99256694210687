.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background-color: #eef5f9;
  width: calc(100% - 250px);
}
.wrapperActive {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background-color: #eef5f9;
  width: calc(100% - 90px);
}
.routeWrapper {
  width: 100%;
  height: 85%;
}
.headerContainer {
  display: flex;
  background-color: white;
  box-shadow: 2px 2px 2px rgb(0 0 0 / 10%);
  width: 100%;
  padding: 20px;
  height: fit-content;
}
.headerContainer > h4 {
  margin: 0 !important;
}
.subHeaderText {
  font-size: 1.3rem;
  font-weight: 800;
  margin-bottom: 0px !important;
}
