.Card {
  display: flex;
  flex-direction: column;
  color: #666666;
  height: fit-content;
  background-color: #fff;
  /* height:inherit; */
  padding: 10px;
  margin: 0 3em 10em 3em;
  box-shadow: 2px 2px 2px rgb(0 0 0 / 10%), -1px 0 2px rgb(0 0 0 / 5%);
  position: relative;
}
.SearchContainer {
  box-shadow: none !important;
  background-color: #fff;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 25px;
}
.SearchBar {
  width: calc(100% - 25px);
  margin: 2px;
  box-shadow: none !important;
  padding: 3px;
  background-color: inherit;
  border-radius: 0.25rem;
}
.SearchBtn {
  text-align: center;
  margin: 2px !important;
  padding: 5px 10px !important;
  background-color: #2b90d9;
  border-color: #2b90d9;
  color: white !important;
  border-radius: 0.25rem !important;
}
.CardHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}
.PaginationDropdown {
  padding: 5px;
}
.CardBody {
  padding: 10px 40px 60px 40px;
  overflow-y: auto;
  overflow-x: hidden;
}
.CardFooter {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  bottom: 0;
}
.pagination {
  width: 100%;
  margin-right: 50px;
}
.PaginationData {
  display: flex;
  justify-content: space-between;
  margin: 0 20px;
}
