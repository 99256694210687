.btn {
    width: fit-content;
    margin: 0.1em;
    height:fit-content;
    background-color: #f8f9fa;
    border:1px solid #2b90d9;
    color: #2b90d9 !important;
    border-radius: 0.25rem;
    padding:4px 12px;
    font-size:20px;
  }
  .btn:hover{
    background-color: inherit;
    box-shadow: none;
    border-color: inherit;
  }
  .btn:active{
    background-color: #ffc107 !important;
    box-shadow: none;
    border-color: inherit;
  }
  .btn:focus{
    background-color: white;
    box-shadow: none;
    border-color: inherit;
  }
  .btn:disabled{
    background-color: inherit;
    border-color: inherit;
  }