.paginationContainer {
  background-color: #fff;
}
.pagination {
  display: flex;
  list-style: none;
  margin: 2em 2em 0em 2em;
  padding: 0;
  justify-content: right;
  height: 70px;
  line-height: 70px;
}
.pagination a {
  cursor: default;
  padding: 0.4em 0.8em;
  border-radius: 5px;
  border: 1px solid #fff;
  color: #333;
  margin-left: 0.5em;
  text-decoration: none;
}
.pagination li:not(.disabled) a:hover {
  background-color: #585858;
  color: white !important;
  cursor: pointer;
  background: linear-gradient(to bottom, #585858 0%, #111 100%);
  text-decoration: none;
  padding: 0.4em 0.8em;
  border: 1px solid #111;
}
.pagination li a {
  font-size: 17px;
}
.pagination li.active a {
  color: #333 !important;
  border: 1px solid #979797;
  background: linear-gradient(to bottom, #fff 0%, #dcdcdc 100%);
}
.pagination li.disabled a {
  pointer-events: none;
  color: rgb(198, 197, 202);
  border: 1px solid rgb(198, 197, 202);
}
