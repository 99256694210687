.homeContainer {
  display: flex;
  justify-content: space-evenly;
  height: 100%;
  color: #2f333e !important;
  font-weight: 600;
  text-align: center;
  overflow-y: scroll;
  flex-wrap: wrap;
  padding: 20px;
}
.homeContainer h1 {
  color: inherit;
}
.homeCard {
  flex-grow: 1;
}
.homeContainerNonAdmin{
  display: flex;
  height: 70vh;
  justify-content: center;
  align-items: center;
  text-align: center;
}
