.footer {
  display: inherit;
  align-items: center;
  position: fixed;
  bottom: 0;
  z-index: 4;
  justify-content: center;
  height: fit-content;
  background-color: #212529;
  color: aliceblue;
  width: 100%;
  height: 3.3em;
  text-align: center;
}
