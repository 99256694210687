.Container {
  line-height: 2.5;
  padding: 50px 0;
  width: 90%;
  width: 100%;
  padding: 1em 2.3em 3em;
}

.backBtn {
  /* margin: 2px; */
  margin-bottom: 20px;
  border-radius: 5px !important;
  background-color: #227bbc;
  border-color: #227bbc;
  font-size: 15px !important;
}
.backBtn:focus {
  box-shadow: none;
}
.backBtn:hover {
  background-color: #006ad3 !important;
}

.titleRow {
  height: 200vh;
}

.thLabel {
  padding: 0% !important;
  height: 20px;
  width: 20px !important;
}
.tdLabel {
  padding: 0% !important;
  height: 20px;
  width: 20px;
}
.table {
  /* width: 100px !important; */
  padding: 0% !important;
}

.titleLabel {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  color: #212529;
  display: inline-block;
  font-weight: 400;
}

.titleInput {
  height: 35px;
  font-size: 1.1rem;
}

/* .titleImage {
  height: 100px;
  width: 20%;
} */

.titleImage1 {
  height: 100px !important;
  width: 100px !important;
  aspect-ratio: auto 100 / 100;
  background-color: rgb(125, 125, 125);
  margin: 10px;
}
.rowBody {
  margin-bottom: 1em;
}
