.navBar {
  background-color: #2f333e;
  color: #ffff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 2px 2px rgb(220, 218, 218);
  padding: 15px 0;
  height: 100%;
  min-height: 8vh;
}
.logoImg {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-left: 20px;
}
.settingImg {
  width: 17px;
  height: 17px;
}
.nav {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffff;
}
.logoutLabel {
  margin-right: 10px;
  background-color: #2f333e;
  color: #fff !important;
}
.reloader {
  color: #ffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logout {
  margin-left: 5px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  color: #ffff;
}
.logout:hover {
  color: #ffff;
}

.active {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  font-size: 28px;
  transition: all 0.3s cubic-bezier(0.81, -0.33, 0.345, 1.375);
}
.nav-link {
  color: rgb(255 255 255);
}
.arrowTopBar {
  background-color: #ffff;
  width: 24px;
  height: 2px;
  /* margin: 4px auto 0; */
  display: block;
  /* transform: translate(2px, 18px); */
}
.arrowBody {
  /* position: relative;
  font-size: 28px;
  transition: all 0.3s cubic-bezier(0.81, -0.33, 0.345, 1.375); */
}
.arrowBottomBar {
  background-color: #ffff;
  width: 24px;
  height: 2px;
  /* margin: 4px auto 0; */
  display: block;
  /* transform: translate( 2px,-19px); */
}
.spanBody {
  flex-direction: column;
  cursor: pointer;
}
.middleArrow {
  background-color: #2f333e;
  width: 100px;
  padding: 0%;
  margin: 0%;
  cursor: pointer;
}
strong {
  font-weight: bolder;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.logoutIcon {
  color: #fff;
}
.rightArrowDiv {
  display: flex;
  margin-left: -4px;
}
.leftArrowDiv {
  display: flex;
  margin-left: -8px;
}
.horizontalLine {
  margin-top: 4px;
  width: 32px;
  background: #fff;
  height: 2px;
}
.rightArrow {
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid #fff;
}
.leftArrow {
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 5px solid #fff;
}

h4 {
  font-size: 16px;
  text-align: center;
}

.btnSecondary:hover {
  background-color: transparent;
  border-left: solid 1px #e4e4e4;
  color: #287ade;
}
.btnPrimary:hover {
  background-color: transparent;
  border-left: solid 1px #e4e4e4;
  color: #287ade;
}

.btnSecondary {
  margin: 0;
  padding: 15px 20px;
  background-color: transparent;
  font-weight: normal;
  border: none;
  border-left: solid 1px #e4e4e4;
  color: #777;
  font-weight: bold;
  font-size: 16px;
}
.btnPrimary {
  margin: 0;
  padding: 15px 20px;
  background-color: transparent;
  font-weight: normal;
  border: none;
  border-left: solid 1px #e4e4e4;
  color: #489afe;
  font-weight: bold;
  font-size: 16px;
}

.popUpHeader {
  padding: 10px 10px;
  height: 70px;
  font-size: 16px;
  line-height: 20px;
  border-bottom: solid 1px #eeeeee;
}
.popUpFooter {
  text-align: right;
  border-top: solid 1px #e4e4e4;
  background-color: #f4f4f4;
  border-radius: 0 0 6px 6px;
  -moz-border-radius: 0 0 6px 6px;
  -webkit-border-radius: 0 0 6px 6px;
  padding: 0;
}

.manageProfileLabel {
  background-color: #2f333e;
  color: #fff !important;
}

.manageProfile {
  margin-left: 5px;
  margin-top: 0px;
  color: #ffff;
}
.manageProfile:hover {
  color: #ffff;
}
