.container {
  display: flex;
  height: 100%;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}
.error {
  color: red;
}
.linkHeading {
  margin-top: 2%;
}
.Link {
  text-decoration: none;
  outline: none;
  font-size: larger;
}
.Link:hover {
  color: green;
}
