.Container {
  line-height: 2;
  padding: 50px 0;
  width: 100%;
}

.backBtn {
  border-radius: 5px !important;
  background-color: #227bbc;
  border-color: #227bbc;
  font-size: 15px !important;
}
.backBtn:focus {
  box-shadow: none;
}
.backBtn:hover {
  background-color: #006ad3 !important;
}

.imgContainer {
  vertical-align: middle;
  /* padding: 0.25rem; */
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: aut0;
  width: 100px;
}

.thLabel {
  padding: 0% !important;
}
.tdLabel {
  padding: 0% !important;
  height: 20px;
  width: 20px;
}
.hoursTable {
  padding: 0% !important;
  line-height: 0% !important;
}

.titleLabel {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  color: #212529;
  display: inline-block;
  font-weight: 400;
}

.titleInput {
  height: 35px;
  font-size: 1.1rem;
}

.titleImage1 {
  height: 100px !important;
  width: 100px !important;
  aspect-ratio: auto 100 / 100;
  background-color: rgb(125, 125, 125);
  margin: 10px;
}
.rowBody {
  margin-bottom: 1em;
}
.titleRow {
  height: 200vh;
}
