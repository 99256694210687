.passwordPageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  min-width: 100vh;
  background-color: #ec222640;
  margin: 0 !important;
}
.brandLogoContainer {
  display: inherit;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 280px !important;
  height: 280px !important;
  background-color: white;
}
.brandLogo {
  width: 80%;
}
.passwordContainer {
  display: inherit;
  flex-direction: column;
  justify-content: center;
  border-radius: 6px;
  box-shadow: 2px 2px 2px rgb(0 0 0 / 10%), -1px 0 2px rgb(0 0 0 / 5%);
  align-items: center;
  max-height: 70vh;
  height: 100%;
  max-width: 60vw;
  width: 100%;
  color: red;
  border-radius: 6px;
  background-color: red;
}
.rightPanel {
  display: inherit;
  color: white;
  height: inherit;
  min-width: inherit;
  justify-content: center;
  align-items: center;
}
.leftPanel {
  display: inherit;
  /* color: red; */
  flex-direction: column;
  align-items: left;
  justify-content: center;
  height: inherit;
  min-width: inherit;
  background-color: white;
  border-radius: 0 6px 6px 0;
}
.formCredentialsInput {
  text-align: start;
  border: none !important;
  width: 90%;
  border-bottom: 1px solid #eee !important;
  margin: 10px;
}
.passwordFloatingLabel {
  color: gray;
}
.passwordButton {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px !important;
  width: fit-content;
  padding: 6px 12px;
  border-radius: 0.25rem;
  background-color: rgb(236 34 38) !important;
  border-color: rgb(236 34 38) !important;
  outline: none !important;
  text-transform: none;
  margin: 10px;
}
.loginButton:focus {
  box-shadow: none !important;
}
.passwordResetLink {
  margin-top: 10px;
  margin-left: 12px;
  color: red;
  text-decoration: none;
}
.passwordResetLink:hover {
  color: red;
  text-decoration: underline;
}

.Error {
  margin-left: 16px;
  color: red;
}
