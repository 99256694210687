.scTimeRow {
  margin-bottom: 50px;
  margin-top: 50px;
}
.scTimeLabel {
  font-size: 13px;
  color: rgb(116, 116, 116);
}
.scTimeInput {
  height: 25px;
  border-radius: 0%;
  border-color: black;
}
.scTimeInput:focus {
  box-shadow: none;
  border-color: black;
}

.scTimeCol {
  margin: 2px;
  padding: 0%;
  width: 7.5rem;
}
hr {
  border: none;
  border-top: 1px dashed rgb(156, 148, 148);
  color: #fff;
  background-color: #fff;
  height: 1px;
  width: 100%;
}
